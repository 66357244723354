<template>
  <div class="tab-text">
    
    <div class="vx-row mb-6" style="width:50%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <vs-button class="ml-4 mt-2" color="primary" icon-pack="feather" icon="icon-prin" @click="downloadExcel('excel')">Download Excel</vs-button>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + ' vertical-divider' : '',
          detailHide,
        ]"
      >
        <data-table
          :responseData="responseData"
          :propsParams="params"
          :header="header"
          @reloadDataFromChild="reloadData"
        >
          <template slot="tbody">
            <vs-tr
              :key="indextr"
              v-for="(tr, indextr) in data"              
            >

              <vs-td>
                <vs-button
                  v-if="dataId != tr.id"
                  v-on:click="clickTr(tr)"
                  color="primary"
                  type="gradient"
                  icon-pack="feather"
                  icon="icon-search"
                ></vs-button>
              </vs-td>
              <vs-td>
                {{ tr.operating_unit }}
              </vs-td>
              <vs-td>
                {{ dateFormat(tr.date) }}
              </vs-td>
              <vs-td>
                {{ dateTimeFormat(tr.created_at) }}
              </vs-td>
              <vs-td>
                {{ tr.code }}
              </vs-td>
              <vs-td>
                {{ tr.partner_name }}
              </vs-td>
              <vs-td>
                {{ tr.source_transaction }}
              </vs-td>
              <vs-td>
                {{ tr.ref_code }}
              </vs-td>
              <vs-td>
                {{ tr.document }}
              </vs-td>
              <vs-td style="text-align:right">
                {{ priceFormat(tr.total) }}
              </vs-td>
              <vs-td>
                {{ tr.posted_date == null ? "Draft" : "Posted"}}
              </vs-td>
              <vs-td>
                {{ tr.result_status == 0 ? "Error" : tr.result_message }}
              </vs-td>
            </vs-tr>
          </template>
        </data-table>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? ' nonfixed vx-col md:w-1/2 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <div>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                size="small"
                v-on:click="closeDetail"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>

            <detail :data-id="dataId" :selected="selectedData"></detail>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import detail from "./detail.vue";
import moment from 'moment'

export default {
  components: {
    detail,
  },
  props: [
    "operatingUnit",
    "processDocument",
    "postingDate"
  ],
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        operatingUnitId: null,
      },
      header: [
        {
          text: "Action",
          // width: '5%'
          value: "code",
        },
        {
          text: "Operating Unit",
          value: "code",
        },
        {
          text: "Date",
          // width: '5%'
          value: "date",
        },
        {
          text: "Created Date",
          value: "created_at",
        },
        {
          text: "Number",
          value: "code",
        },
        {
          text: "Partner",
          value: "partner_name",
        },
        {
          text: "Source Transaction",
          value: "source_transaction",
        },
        {
          text: "Reference",
          value: "ref_code",
        },
        {
          text: "Journal Name",
          value: "code",
        },
        {
          text: "Total",
          value: "total",
        },
        {
          text: "Status",
          value: "posted_date",
        },
        {
          text: "Sync",
          value: "result_message",
        },
      ],
      data: [],
      responseData: {},
      isActive: 1,

      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      selectedData: {},
      dataId: 0,
      process_document_ids: "",
    };
  },
  watch: {      
    "operatingUnit.selected": function (val) {
      console.log("watch", val)
      this.params = {
        search: "",
        length: 10,
        page: 1,
      },
      this.reloadData(this.params);
    },
    "processDocument.selected": function (val) {
      console.log("watch2", val)
      this.setIDProcessDocument();
      this.params = {
        search: "",
        length: 10,
        page: 1,
      },
      this.reloadData(this.params);
    },
    "postingDate.endDate": function (val) {
      console.log("watch3", val)
      this.params = {
        search: "",
        length: 10,
        page: 1,
      },
      this.reloadData(this.params);
    },
  },
  methods: {
    downloadExcel(file) {

      this.$vs.loading();
      var fileTitle =
          "journal_failed" +
          "_" +
          moment(this.startDate).format("YYYY-MM-DD") +
          "_-_" +
          moment(this.endDate).format("YYYY-MM-DD");

      this.$http
          .get("/api/v1/report/journal/export", {
          params: {
              order: "id",
              sort: "desc",
              file: file,
              title: fileTitle,
              operating_unit_id: this.operatingUnit.selected ? this.operatingUnit.selected.ID : 0,
              process_document_id: this.process_document_ids,
              start_posting_date: this.postingDate.startDate != null ? moment(this.postingDate.startDate).format("YYYY-MM-DD") : null,
              end_posting_date: this.postingDate.endDate != null ? moment(this.postingDate.endDate).format("YYYY-MM-DD") : null,
              sync: "failed",
          },
          })
          .then((resp) => {
          this.$vs.loading.close();
          if (resp.status == "error") {
              this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
              });
          } else {
              
              this.$vs.notify({
              color: "success",
              title: "Processing",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
              });
          }
      });

    },
    clickTr(val) {
      this.closeDetail();
      setTimeout(function () {}, 3000);
      this.dataId = val.id ? val.id : val.ID;
      this.selectedData = val;
      this.detail = true;
    },
    closeDetail() {
      this.dataId = 0;
      this.detail = false;
    },
    reloadData(params) {
      console.log("reload data")
      this.params = params;
      this.$vs.loading();
      this.$http
        .get("api/v1/report/journal", {
          params: {
            search: this.params.search,
            length: this.params.length,
            page: this.params.page,
            operating_unit_id: this.operatingUnit.selected ? this.operatingUnit.selected.ID : 0,
            process_document_id: this.process_document_ids,
            start_posting_date: this.postingDate.startDate != null ? moment(this.postingDate.startDate).format("YYYY-MM-DD") : null,
            end_posting_date: this.postingDate.endDate != null ? moment(this.postingDate.endDate).format("YYYY-MM-DD") : null,
            sync: "failed",
          },
        })
        .then((resp) => {
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.data = resp.data.records;
            this.responseData = resp.data;
            this.recordsTotal = resp.data.recordsTotal;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    dateFormat(date) {
      if (date) {
          let dt = new Date(date)
          let d = dt.getDate()
          let m = dt.getMonth()+1
          let y = dt.getFullYear()
          return d+"/"+m+"/"+y
      }
    },
    dateTimeFormat(date) {
      if (date) {
        return moment(date)
				.add(-7, "h")
				.utc()
				.local();
      }
    },
    setIDProcessDocument() {
        let datas = this.processDocument.selected
        let keys = Object.keys(datas)
        let count = datas.length
        let dataIDS = ""
        console.log("setIDProcessDocument", datas)
        keys.forEach(function (i) {
            if (datas[i].ID != 0) {
                dataIDS += datas[i].ID
                if ((parseInt(i) + 1) != count) {
                    dataIDS += ","
                }
            }
        });
        this.process_document_ids = dataIDS
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 50%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.red {
  background: red;
  background-color: red;
}
.yellow {
  background: yellow;
  background-color: yellow;
}
</style>